import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import NavbarRight from '../home/NavbarRight';
import HomeHeader from '../home/HomeHeader';
import LoggedInNavbar from './LoggedInNavbar';

import CareerPreppedLogoOnly from '../../assets/images/logo-icon.png';
import CareerPreppedLogo from '../../assets/images/CP_logo.png';

const HeaderComponent = (props) => {
  const { pathname } = location;
  const {
    showNavHomepage,
    toggleNavHomepage,
    toggleLoginModal,
    toggleRequestInvite,
    onCloseWindow,
    onSearchIconClicked,
    currentUser,
    isMobile,
    isTalentEmployersOpen,
    toggleTalentEmployers,
    openRecruiterModal,
    toggleSignUpModal
  } = props;

  const hideNavLookupArray = ['invitation-password', 'signup', 'new-password', 'group-admin-invite','ohio'];

  const hideNavRight = hideNavLookupArray.includes(pathname.split('/')[2]);

  const isResearchPage = pathname.includes('research');
  const isOhioPage = pathname.includes('ohio');
  const isBPAPage = pathname.includes('bpa');
  const isInReEnterCredentialsPage = pathname.includes('reenter-credentials');
  const cpLogo = isMobile ? CareerPreppedLogoOnly : CareerPreppedLogo;

  if ((!props.isAuthenticated) && !isResearchPage && !isOhioPage && !isBPAPage) {
    return (
      <HomeHeader
        isMobile={isMobile}
        showNavHomepage={showNavHomepage}
        toggleLoginModal={toggleLoginModal}
        toggleNavHomepage={toggleNavHomepage}
        toggleRequestInvite={toggleRequestInvite}
        isAuthenticated={props.isAuthenticated}
        isTalentEmployersOpen={isTalentEmployersOpen}
        toggleTalentEmployers={toggleTalentEmployers}
        openRecruiterModal={openRecruiterModal}/>
    );
  }

  return(
    <div>
      <section className="navigation nav-login">
        <div id="main-nav" className="navbar container">
          <div className="navbar-top">
            {isOhioPage || isBPAPage ? 
              <img src={cpLogo}
                className="img-responsive"
                height="24"
                alt="CareerPrepped"
                style={{marginTop: '10px',marginBottom: '10px'}}/>
              : 
              <Link
                className="navbar-brand"
                to={props.isAuthenticated ? '/home' : '/'}>
                <img src={cpLogo}
                  className="img-responsive"
                  height="24"
                  alt="CareerPrepped"/>
              </Link>
            }
          </div>

          {isResearchPage &&
            <div
              onClick={onCloseWindow}
              className="close-window-btn clickable">
              <span>X</span>
            </div>}

          {(!isResearchPage && !isInReEnterCredentialsPage && !isOhioPage && !isBPAPage) &&
            <div id="exCollapsingNavbar1">
              {props.isAuthenticated &&
                <LoggedInNavbar
                  onSearchIconClicked={onSearchIconClicked}
                  currentUser={currentUser}
                  isMobile={isMobile}/>}

              {(!props.isAuthenticated && !hideNavRight) &&
                <NavbarRight
                  toggleLoginModal={toggleLoginModal}
                  toggleSignUpModal={toggleSignUpModal}
                  toggleRequestInvite={toggleRequestInvite}
                  header/>}

            </div>}
        </div>
      </section>
    </div>
  );
};

HeaderComponent.propTypes = {
  isMobile: PropTypes.bool,
  toggleLoginModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirectToSearch: PropTypes.func.isRequired,
  isClicked: PropTypes.string,
  showNavHomepage: PropTypes.bool.isRequired,
  toggleNavHomepage: PropTypes.func.isRequired,
  onCloseWindow: PropTypes.func,
  onSearchIconClicked: PropTypes.func,
  toggleRequestInvite: PropTypes.func.isRequired,
  isTalentEmployersOpen: PropTypes.bool.isRequired,
  toggleTalentEmployers: PropTypes.func.isRequired,
  openRecruiterModal: PropTypes.func.isRequired,
  toggleSignUpModal: PropTypes.func.isRequired
};

export default HeaderComponent;
