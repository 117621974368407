import React from 'react';
import { Link } from 'react-router-dom';

const HomeFooter = () => {
  const { pathname } = location;
  const sStyle = {fontSize: '1rem'};
  const hideNavRight = pathname.includes('auth/signup/') || pathname.includes('research');
  const isOhioPage = pathname.includes('ohio');
  const isBpaPage = pathname.includes('bpa');
  return (
    <footer>
      <div className="container footer-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 logo-container">
            <span className="logo footer-logo float-xs-none">
              <a href="https://www.maxknowledge.com/" target="_blank" rel="noopener noreferrer">
                <span style={sStyle}>Powered by MaxKnowledge</span>
              </a>
            </span>
          </div>
          {
            !hideNavRight && !isOhioPage && !isBpaPage &&
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="footer-nav">
                  <span><Link to="/about">About </Link>&nbsp;|&nbsp;</span>
                  <span><Link to="/partners">Partners </Link>&nbsp;|&nbsp;</span>
                  <span><a href="https://help.careerprepped.com/help" target="_blank" rel="noopener noreferrer">Help</a>&nbsp;|&nbsp;</span>
                  <span><Link to="/contact">Contact </Link>&nbsp;|&nbsp;</span>
                  <span><Link to="/media">Media </Link>&nbsp;|&nbsp;</span>
                  <span><Link to="/terms">Terms</Link>&nbsp;|&nbsp;</span>
                  <span><Link to="/privacy">Privacy</Link></span>
                </div>
              </div>
          }
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
